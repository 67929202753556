import { createSlice } from "@reduxjs/toolkit";

export const measureSpecNamesSlice = createSlice({
  name: "measureSpecNames",
  initialState: { isLoading: true, data: [], error: null },
  reducers: {
    addAll: (state, action) => {
      state.data.length = 0;
      state.data.push(...action.payload.data);
    },
    add: (state, action) => {
      if (action.payload.data.parentId == 0) {
        state.data.push(action.payload.data);
      } else {
        const parent = state.data.find(
          (item) => item.id === action.payload.data.parentId
        );
        if (parent) {
          if (parent.childs == null) {
            parent.childs = [];
          }
          parent.childs.push(action.payload.data);
        }
      }
    },
    update: (state, action) => {
        console.log(action.payload.data);
      if (action.payload.data.parentId == 0 || action.payload.data.parentId == null) {
        state.data[action.payload.index].name = action.payload.data.name;
      } else {
        const child = state.data[action.payload.index].childs.find(
            (item) => {
                return item.id === action.payload.data.id
            }
          );

          child.name = action.payload.data.name;
      }
    },
    remove: (state, action) => {
      state.data.splice(action.payload.index, 1);
    },
    error(state, action) {
      state.error = action.payload.error;
    },
  },
});

export const { addAll, add, update, remove, error } =
  measureSpecNamesSlice.actions;
export default measureSpecNamesSlice.reducer;
