import { configureStore } from '@reduxjs/toolkit'
import brandsReducer from './features/brands/redux/brandsSlice'
import categoriesSlice from './features/categories/redux/categoriesSlice'
import setsSlice from './features/sets/redux/setsSlice'
import materialsSlice from './features/materials/redux/materialsSlice'
import itemDetailsSlice from './features/item_details_variant/redux/itemDetailsSlice'
import itemsSlice from './features/items/redux/itemsSlice'
import  variantStocksSlice  from './features/item_variant_stocks/redux/variantStocksSlice'
import authSlice from './features/auth/redux/authSlice'
import sideBarShowSlice from './sideBarShowSlice'
import variantsSlice from './features/variants/redux/variantsSlice'
import ordersSlice from './features/orders/redux/ordersSlice'
import measureSpecNamesSlice from './features/measure_spec_names/redux/measureSpecNamesSlice'
import sizesSlice from './features/sizes/redux/sizesSlice'
import materialWidthsSlice from './features/material_widths/redux/materialWidthsSlice'
import designsSlice from './features/designs/redux/designsSlice'
import designDetailsSlice from './features/designs/redux/designDetailsSlice'
import materialStocksSlice from './features/material_stocks/redux/materialStocksSlice'
import carouselSlice from './features/carousel/redux/carouselSlice'

export default configureStore({
  reducer: {
    auth: authSlice,
    brands: brandsReducer,
    categories: categoriesSlice,
    sets: setsSlice,
    materials: materialsSlice,
    items: itemsSlice,
    variants: variantsSlice,
    itemDetails: itemDetailsSlice,
    variantStocks: variantStocksSlice,
    sidebarShow: sideBarShowSlice,
    orders: ordersSlice,
    measureSpecNames: measureSpecNamesSlice,
    sizes: sizesSlice,
    materialWidths: materialWidthsSlice,
    designs: designsSlice,
    designDetails: designDetailsSlice,
    materialStocks: materialStocksSlice,
    carousel: carouselSlice
  },
})