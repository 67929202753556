import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  data: null,
  error: null,
  pieces: [],
  costStructure: [],
  materialConsumption: [],
  measurements: [],
  materialCombinations: [],
  pieceOptions: [],
  sizeGroupOptions: [],
  sizeOptions: [],
  materialWidthOptions: [],
};

export const designDetailsSlice = createSlice({
  name: "designDetails",
  initialState,
  reducers: {
    setDetails: (state, action) => {
      state.data = action.payload.data;
    },
    setPieces: (state, action) => {
      state.pieces = action.payload;
    },
    setCostStructure: (state, action) => {
      state.costStructure = action.payload;
    },
    setMaterialConsumption: (state, action) => {
      state.materialConsumption = action.payload;
    },
    setMeasurements: (state, action) => {
      state.measurements = action.payload;
    },
    setMaterialCombinations: (state, action) => {
      state.materialCombinations = action.payload;
    },
    setPieceOptions: (state, action) => {
      state.pieceOptions = action.payload;
    },
    setSizeGroupOptions: (state, action) => {
      state.sizeGroupOptions = action.payload;
    },
    setSizeOptions: (state, action) => {
      state.sizeOptions = action.payload;
    },
    setMaterialWidthOptions: (state, action) => {
      state.materialWidthOptions = action.payload;
    },
    addVariant: (state, action) => {
      state.data.variants.push(action.payload.data);
    },
    updateVariant: (state, action) => {
      state.data.variants[action.payload.index] = action.payload.data;
    },
    removeVariant: (state, action) => {
      state.data.variants.splice(action.payload.index, 1);
    },
    error(state, action) {
      state.error = action.payload.error;
    },
  },
});

export const {
  setDetails,
  setPieces,
  setCostStructure,
  setMaterialConsumption,
  setMeasurements,
  setMaterialCombinations,
  addVariant,
  updateVariant,
  removeVariant,
  setPieceOptions,
  setMaterialWidthOptions,
  setSizeGroupOptions,
  setSizeOptions,
  error,
} = designDetailsSlice.actions;

export default designDetailsSlice.reducer;
