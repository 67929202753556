import { createSlice } from '@reduxjs/toolkit'

export const sizesSlice = createSlice({
    name:'sizes',
    initialState: {isLoading: true, data: [], error: null},
    reducers:{
        addAll:(state, action) => {
            state.data.length = 0;
            state.data.push(...action.payload.data);
        },
        add:(state, action) => {
            state.data.push(action.payload.data);
        },
        update: (state, action) => {
            state.data[action.payload.index] = action.payload.data;
        },
        remove: (state, action) => {
            state.data.splice(action.payload.index,1)
        },
        error(state, action){
            state.error = action.payload.error
        }
    }
})

export const {addAll, add, update, remove, error } = sizesSlice.actions
export default sizesSlice.reducer